@import '../../assets/scss/variables';

.admin-login-section {
    width: 100%;
    height: 100vh;
    background: url('../../assets/images/admin-login-bg.jpg') no-repeat;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-wrapper {
        width: 100%;
        max-width: 520px;
        margin: 0 auto;
        border-radius: 10px;
        background: $white;
        box-shadow: 0px 0px 30px 20px rgba(0, 0, 0, 0.05);
        z-index: 1;
        border-bottom: 3px solid $secondary;

        .login-btn {
            background: $secondary;
            border: none;
            border-radius: 5px;
            height: 50px;

            span {
                font-weight: 500;
            }

            &:hover {
                background: $primary;
            }
        }

        .p-inputtext {
            height: 45px;
            font-size: 14px;
            font-family: $font-base;
            border: 1px solid $blue-light;
            width: 100%;
            &.afid{
                padding-right: 2rem;
            }
        }

        .password-left {
            .p-password {
                .p-inputtext {
                    width: 100%;
                    padding-right: 0rem;
                    padding-left: 2.5rem;
                }

                .pi-eye {
                    right: auto;
                    left: 0.75rem;
                }

                .pi-eye-slash {
                    @extend .pi-eye;
                }
            }
        }

        i {
            color: $primary !important;
            font-size: 15px;
            margin-top: -7px;
            &.pi-user {
                font-size: 14px;
            }
        }
        .p-chips{
            width: 100%;
            .p-chips-multiple-container{
                width: 100%;
                padding-left: 2.5rem;
                height: auto;
                max-height: 75px;
                overflow-y: scroll;
                scrollbar-color: $primary $white;
                scrollbar-width: auto;
                .p-chips-input-token{
                    input{
                        font-size: 14px;
                    }
                }
                .p-chips-token{
                    margin-bottom: 5px;
                }
                &::-webkit-scrollbar {
                    width: 5px;
                }
                &::-webkit-scrollbar-track {
                    background: $white;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $primary;
                    border-radius: 10px;
                    padding: 2px;
                }
            }
        }
        .afid-span{
            position: absolute;
            right: 20px;
            top: 24px;
            color: $primary;
            cursor: pointer;
            &:hover{
                color: $secondary;
            }
        }
        .affiliate-forms{
            padding: 35px 35px 10px;
        }
        .forgot-password {
            font-size: 16px;
            margin-top: 10px !important;
            margin-bottom: 10px !important;
            color: $black; 
        
            a {
                color: $primary; 
                text-decoration: none;
            }
        
            a:hover {
                text-decoration: underline;
            }
        }
        
        .heading-forms{
            h3 {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 10px !important;
                margin-top: 1px !important;
                color: $primary;
            }

            p {
                font-size: 16px;
                margin-top: 20px !important;
                margin-bottom: 20px !important;
                color: $black; 
            }
        }
        .email-verification-form{
            padding: 35px 35px 10px;
            h3{
                margin: 0px 0px 16px;
            }
            h5{
                margin: 0px 0px 20px;
            }
        }
    }
    .login-header {
        background: $primary;
        border-radius: 10px 10px 0px 0px;
        padding: 20px 35px;

        .logo-img {
            img {
                width: 120px;
            }
        }

        h1 {
            color: $white;
            margin: 0px;
            padding: 0px;
            font-weight: 500;
            font-size: 20px;
        }
    }

    p {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: $primary;
        margin-top: 65px;

        a {
            color: $primary;
            font-weight: 500;

            span {
                color: $secondary;
            }
        }
        &.login-p{
            margin-top: 10px;
            font-size: 15px;
            color: $black;
            a{
                color: $secondary;
                text-decoration: underline;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
    &.signup-section{
        display: block;
        padding: 4.2% 0%;
        height: 100%;
    }
}
.p-tooltip .p-tooltip-text{
    padding: 0.5rem 0.75rem;
    font-size: 13px;
    background: $primary;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow{
    border-top-color:$primary;
}
@media (max-width:768px){
    .admin-login-section .login-wrapper{
        width: 90%;
    }
}