@import '../../assets/scss/variables';

.main {
    background: #F6FDFF;
    min-height: calc(100vh - 0px);
}

.admin-header-section {
    background: url(../../assets/images/admin-header-bg.jpg) no-repeat;
    padding: 35px 65px;
    min-height: 150px;

    h1 {
        margin: 0px;
        color: $primary;
        font-size: 22px;
    }
    .logo{
        img{
            width: 100%;
            max-width: 200px;
        }
    }
}

.admin-header {
    padding: 0px 65px;
    position: relative;
    top: -30px;

    .p-menubar {
        background: $white;
        border: 1px solid #DEE2FF;
        box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        padding: 0px 25px;

        .p-menubar-root-list {
            .p-menuitem {
                margin-right: 50px;
                margin-bottom: -4px;

                .p-menuitem-link {
                    border-radius: 0px;
                    padding: 0px;
                    line-height: 58px;
                    display: inline-block;

                    .p-menuitem-text {
                        font-weight: 500;
                        font-size: 14px;
                        color: $black;
                    }

                    .p-menuitem-icon {
                        font-size: 14px;
                        color: $black;
                    }

                    &:hover {
                        color: $primary;
                        background: transparent !important;

                        .p-menuitem-text {
                            color: $primary !important;
                        }

                        .p-menuitem-icon {
                            color: $primary;
                        }

                        &::before {
                            display: block;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background: $secondary;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        border-radius: 0px;
                        display: none;
                    }
                }

                &.active-menu {
                    margin-bottom: -4px;

                    .p-menuitem-link {
                        &::before {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .p-menubar-end {
        .p-button {
            background: transparent;
            border: none;
            padding: 0px 0px 0px 10px;

            .p-button-label {
                color: $primary;
                font-weight: 500;
                font-size: 14px;
            }

            .p-button-icon {
                color: $primary;
                font-size: 15px;
                margin-top: 2px;
            }

            &:hover {
                background: transparent;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .user-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            background: $primary;
            text-align: center;
            border-radius: 30px;
            line-height: 24px;

            i {
                color: $white;
                font-size: 12px;
            }
        }
    }
}

.affiliate-footer {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    p {
        font-size: 13px;
        margin: auto;
        padding: 20px 0px;
        text-align: center;
        color: $primary;

        a {
            color: $secondary;
        }
    }
}

.admin-sales-section {
    padding: 0px 65px 35px;
    min-height: calc(100vh - 244px);

    .heading-sec {
        h2 {
            margin: 0px;
            font-weight: 500;
            font-size: 24px;
            color: $primary;
        }

        .searchBtn {
            background: $primary;
            border: none;
            border-radius: 5px;
            height: 40px;
            margin-left: 10px;

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $black;
            }
        }

        .exportBtn {
            background: $primary;
            border: none;
            border-radius: 5px;
            height: 40px;
            margin-right: 10px;

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $secondary;
            }
        }

        .resetBtn {
            background: $secondary;
            border: none;
            border-radius: 5px;
            height: 40px;
            margin-left: 10px;

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                background: $primary;
            }
        }

        .filter-right {
            display: flex;
            align-items: center;
            // gap: 15px;

            .p-multiselect{
                margin-left: 10px;
                min-width: 200px;
                text-align: left;
                .p-multiselect-label{
                    height: 40px;
                    padding: 0.6rem 0.75rem;
                }
            }
            .p-calendar{
                margin-left: 10px;
            }
            .p-datepicker-trigger {
                background: #daedf2;
                border: 1px solid #daedf2;
                height: 40px;

                .pi-calendar {
                    color: $primary;
                }
            }

            .p-inputtext {
                height: 40px;
                font-size: 15px;
            }

            .purchaseStatusDropdown {
                border: 1px solid #daedf2;
            }

            .p-calendar-w-btn-right {
                .p-inputtext {
                    border: 1px solid #daedf2;
                }
            }
        }
    }

    .sales-data-table {
        margin: 30px 0px;
        background: $white;
        box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.02);
        border-radius: 10px;

        .custom-table {
            border: 1px solid #E7EBFF;
            margin-top: 15px;

            .custom-table-header {
                background: #3a476a;
                border-radius: 10px 10px 0px 0px;

                .table-grid {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .col-ul {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-grow: 1;
                        flex-basis: 0;
                        padding: 0rem 1.5rem;

                        li {
                            list-style: none;
                            display: inline-block;
                            width: 13%;
                            color: #fff;
                            font-size: 15px;
                            font-weight: 600;

                            &.dataPlanList {
                                width: 18%;
                            }
                            &.price {
                                width: 8%;
                            }
                            &.orderID{
                                width: 20%;
                            }

                            span {
                                cursor: pointer;
                                padding: 15px 10px;
                                margin-left: -10px;

                                i {
                                    font-size: 13px;
                                    margin-left: 4px;
                                    cursor: pointer;
                                }

                                &:hover, &.selectedList {
                                    background: #425385;
                                }
                            }
                        }
                    }
                }
            }
            .copy-button{
                &:focus{
                    box-shadow: none;
                }
            }

            .p-dataview {
                min-height: 50px;
                position: relative;

                .p-dataview-content {
                    background: #ffffff;
                    color: #495057;
                    border: 0 none;
                    padding: 0;

                    .grid {
                        display: flex;
                        flex-wrap: wrap;
                        margin-right: 0;
                        margin-left: 0;
                        margin-top: 0;

                        .custom-table-body {
                            .table-grid {
                                display: flex;
                                flex-wrap: wrap;
                                border-bottom: 1px solid #E7EBFF;
                                align-items: center;

                                .col-ul {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    flex-grow: 1;
                                    flex-basis: 0;
                                    margin:0px;
                                    padding: 0rem 1.5rem;
                                    li {
                                        list-style: none;
                                        display: inline-block;
                                        width: 13%;
                                        color: #161b28;
                                        font-size: 14px;
                                        font-weight: 400;
                                        vertical-align: middle;
                                        line-height: 16px;

                                        &.dataPlanList {
                                            width: 18%;
                                        }
                                        &.price {
                                            width: 8%;
                                        }
                                        &.orderID{
                                            width: 20%;
                                            word-wrap: break-word;
                                        }

                                        .status {
                                            background: #FAFBFF;
                                            border-radius: 5px;
                                            font-weight: 400;
                                            font-size: 13px;
                                            padding: 4px 15px;

                                            &.completed {
                                                color: #67b173;
                                                background-color: rgba(103, 177, 115, 0.15);
                                            }

                                            &.pending {
                                                color: #efb226;
                                                background-color: rgba(255, 200, 75, 0.15);
                                            }

                                            &.failed {
                                                color: #f17171;
                                                background-color: rgba(241, 113, 113, 0.15);
                                            }
                                        }

                                    }
                                }
                            }
                        }

                        .p-dataview-emptymessage {
                            text-align: center;
                            padding: 20px;
                        }
                    }
                }
            }
        }
    }
}

.p-panel .p-panel-content {
    padding: 0.5rem 1.25rem !important;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    font-size: 13px;
}

.p-breadcrumb {
    background: none !important;
    border: none !important;
    font-size: 14px;
}

.pagination {
    display: flex;
    align-items: center;

    button {
        border: none;
        border-radius: 5px;
        background: $blue-light;
        color: $primary;
        padding: 6px 20px;
        cursor: pointer;

        &:hover {
            background: $primary;
            color: $white;
        }

        &.active {
            width: auto;
            background: $primary;
            padding: 6px 20px;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .active {
        background: $secondary;
        margin: 0px 8px;
        width: 25px;
        text-align: center;
        padding: 4px;
        border-radius: 5px;
        color: $white;
    }
}

.purchaseStatusDropdown {
    width: 200px !important;
}
p{
    line-height: 140%;
}
.affiliate-link-container {
    align-items: center;
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
    .affiliate-link-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 160%;
    }
    .link-block {
        align-items: center;
        background: #eef2ff;
        border: 1px solid #dee4f7;
        border-radius: 8px;
        display: flex;
        gap: 5px;
        padding: 10px 20px;
        .affiliate-link {
            display: inline-block;
            max-width: 665px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
        }
        .pi {
            cursor: pointer;
            font-size: 20px;
            margin-left: 1.4rem;
            &:hover{
                color: $secondary;
            }
        }
    }
}
.p-datepicker{
    &.p-datepicker-multiple-month{
        .p-datepicker-group{
            border-right: 1px solid #dee2e6;
            border-left: none;
            &:last-child{
                border-right: 0 none;
            }
        }
    }
    .p-datepicker-buttonbar{
        padding: 4px 0;
        .p-button{
            padding: 0.2rem 1.25rem;
        }
    }
    table td{
        padding: 2px;
        span{
            width: 2rem;
            height: 2rem;
            font-size: 14px;
            &.p-highlight{
                background: $primary;
                color: $white;
            }
        }
        &.p-datepicker-today{
            span{
                background: $secondary;
                color: $white;
                &:hover{
                    background: $primary !important;
                    color: $white !important;
                }
            }
        }
    }
    .p-timepicker{
        padding: 2px;
        span{
            font-size: 16px;
        }
        button{
            height: 1.5rem;
            &:focus{
                box-shadow: none;
            }
        }
    }
    .p-datepicker-header{
        padding: 0.2rem 0.5rem;
    }
}